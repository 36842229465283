const $ = jQuery

import {getBoilerAmounts} from "../components/search-form"

// TODO: Kijken naar probleem met M76724. Debug de calculate_prices() functie.

$(document).ready(function () {
    let report

    $('#sbp-boilers-7620527').prop('checked', false)
    $('#sbp-boilers-7808381').prop('checked', false)

    $('[id^="sbp-boilers-"]').on('change', function (e) {
        e.preventDefault()

        const field = $(this)

        if (field.is(':checked')) {
            if ( $.inArray( field.val(), [ '7620527', '7808381' ]) !== -1 ) {
                $('#sbp-boilers-7699463').prop('checked', false)
                $('#sbp-boilers-7699464').prop('checked', false)
                $('#sbp-boilers-7699465').prop('checked', false)
                $('#sbp-boilers-7699466').prop('checked', false)
            }

            if ($.inArray(field.val(), ['7699463', '7699464', '7699465', '7699466']) !== -1) {
                $('#sbp-boilers-7620527').prop('checked', false)
                $('#sbp-boilers-7808381').prop('checked', false)
            }
        }
    })

    $('#boiler-1, #boiler-2, #boiler-3, #boiler-4').on('change', function (evt) {
        let idx = parseInt(evt.target.id.slice(-1))

        const field = $(this)

        const boiler_amount_field = '#boiler-amount-' + idx

        const amount = parseInt($('#amount').val())
        const boiler_amounts = getBoilerAmounts()
        const boiler_amount = amount - boiler_amounts

        if (boiler_amount > 0) {
            if (field.val() === '7620527') {
                const last = $(boiler_amount_field + ' option:last').val()

                $(boiler_amount_field).val(last).prop('disabled', true)
            } else {
                $(boiler_amount_field).prop('disabled', false)
            }
        }
    })

    $('#boiler-amount-1, #boiler-amount-2, #boiler-amount3').on('change', function (e) {
        e.preventDefault()

        const current_field = $(this)

        let disable_boilers = []

        $.each(['#boiler-1', '#boiler-2', '#boiler-3', '#boiler-4'], function (idx, id) {
            const part_number = $(id + ' option:selected').val()

            if (part_number !== '') {
                disable_boilers.push(part_number)
            }
        })

        current_field.closest('.field').nextAll().each(function () {
            const field = $(this)
            const select = field.find('select')

            if (select.length === 0) {
                return
            }

            const id = select.attr('id')

            if ( $.inArray( id, [ 'boiler-2', 'boiler-3', 'boiler-4' ] ) === -1 ) {
                return
            }

            $.each([ '7699463', '7699464', '7699465', '7699466' ], function ( idx, part_number ) {
                if ( $.inArray( part_number, disable_boilers ) !== -1 ) {
                    field.find( 'option[value="7620527"]' ).prop( 'disabled', true )
                    field.find( 'option[value="7808381"]' ).prop( 'disabled', true )
                }
            })

            $.each([ '7620527', '7808381' ], function ( idx, part_number ) {
                if ( $.inArray( part_number, disable_boilers ) !== -1 ) {
                    field.find( 'option[value="7699463"]' ).prop( 'disabled', true )
                    field.find( 'option[value="7699464"]' ).prop( 'disabled', true )
                    field.find( 'option[value="7699465"]' ).prop( 'disabled', true )
                    field.find( 'option[value="7699466"]' ).prop( 'disabled', true )
                }
            })
        })
    })

    $(document).on('cascadetool-configurations-searched', (e, data) => {
        const searchType = data.detail.params.search_type

        if (searchType === 'search_by_boiler') {
            document.querySelector('.cascadetool__results .cascadetool__results__actions').remove()
        }
    })

    $(document).on('cascadetool:report_loaded', function (e, data) {
        // Get report from DOM.
        report = $('#report');


        const dn150_category = $('#category-rookgasafvoer-dn150')
        const dn200_category = $('#category-rookgasafvoer-dn200')

        $('td.selection input[type="checkbox"]', dn150_category).each(function () {
            $(this).attr('checked', false);
        });
        $('td.selection input[type="checkbox"]', dn200_category).each(function () {
            $(this).attr('checked', false);
        });

        // Get amount for specifically Quanta Ace 45.
        const qace45_amount = report.find('[data-part-number="7699463"]').data('amount')

        // Set the Quanta Ace 45 amount for the M76724 product.
        window.change_product_amount('M76724', qace45_amount)

        // Make an copy of the M76724. This is because of the multiple times the product must be added.
        const m76724_copy = report.find('[data-part-number="M76724"]').clone()

        // Check if the element is found.
        if ( m76724_copy.length ) {
            // Change part number and id so it's unique.
            m76724_copy
                .attr( 'data-part-number', 'M76724-2' )
                .attr( 'id', m76724_copy.attr( 'id' ) + '-2' )

            // Uncheck the original M76724, because it is in the DN200 category and so it must be unchecked.
            window.hide_product('M76724')

            // Add the copy to the DN150 category.
            report.find( '[data-part-number="7620125"]' ).after( m76724_copy )
        }

        window.calculate_prices()

        report.on('change', 'input[type="checkbox"]', function (e) {
            e.preventDefault()

            const dn150_category = $('#category-rookgasafvoer-dn150')
            const dn200_category = $('#category-rookgasafvoer-dn200')

            const checkbox = $(this)
            const product = checkbox.closest('tr')
            const category = product.closest('.category')

            if (category.attr('id') === 'category-rookgasafvoer-dn150' && checkbox.is(':checked')) {
                dn150_category.find('thead input[type="checkbox"]').prop('checked', true)
                dn200_category.find('thead input[type="checkbox"]').prop('checked', false)
                dn200_category.find('.product').each(function () {
                    window.hide_product( $(this).data( 'part-number' ) )
                })
            }

            if (category.attr('id') === 'category-rookgasafvoer-dn200' && checkbox.is(':checked')) {
                dn200_category.find('thead input[type="checkbox"]').prop('checked', true)
                dn150_category.find('thead input[type="checkbox"]').prop('checked', false)
                dn150_category.find('.product').each(function () {
                    window.hide_product( $(this).data( 'part-number' ) )
                })
            }

            if (product.data('part-number') === 111788) {
                if (checkbox.is(':checked')) {
                    window.show_product('111167')
                } else {
                    window.hide_product('111167')
                }
            }

            window.calculate_prices()
        })

        report.on('click', '[data-action="change-amount"]', function (e) {
            e.preventDefault()

            const dn150_category = $('#category-rookgasafvoer-dn150')
            const dn200_category = $('#category-rookgasafvoer-dn200')

            const category = $(this).closest('.category')

            if (category.attr('id') === 'category-rookgasafvoer-dn150') {
                dn150_category.find('thead input[type="checkbox"]').prop('checked', true)
                dn200_category.find('thead input[type="checkbox"]').prop('checked', false)
                dn200_category.find('.product').each(function () {
                    window.hide_product($(this).data('part-number'))
                })
            }

            if (category.attr('id') === 'category-rookgasafvoer-dn200') {
                dn200_category.find('thead input[type="checkbox"]').prop('checked', true)
                dn150_category.find('thead input[type="checkbox"]').prop('checked', false)
                dn150_category.find('.product').each(function () {
                    window.hide_product($(this).data('part-number'))
                })
            }

            window.calculate_prices()
        })
    })
})
