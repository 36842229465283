/**
 *
 * @type {jQuery|HTMLElement|*}
 */

const $ = jQuery

const hideable_fields = [
    'boiler-1',
    'boiler-amount-1',
    'boiler-2',
    'boiler-amount-2',
    'boiler-3',
    'boiler-amount-3',
    'boiler-4',
    'boiler-amount-4'
]

export const searchForm = () => {
    $('#amount').on('change', () => {
        const first_boiler = $('#boiler-1')

        resetNextFields(first_boiler)

        first_boiler.prop('selectedIndex', 0)

        $(first_boiler).closest('.field').removeClass('hidden')
    })

    $('#boiler-1, #boiler-2, #boiler-3, #boiler-4').on('change', function (event) {
        let idx = parseInt(event.target.id.slice(-1))

        const field = $(this)

        resetNextFields(field)

        const boiler_amount_field = '#boiler-amount-' + idx

        const amount = parseInt($('#amount').val())
        const boiler_amounts = getBoilerAmounts()
        const boiler_amount = amount - boiler_amounts

        if (boiler_amount > 0) {
            populateBoilerAmountField(boiler_amount_field, 1, boiler_amount)

            $(boiler_amount_field).closest('.field').removeClass('hidden')
        }
    })

    $('#boiler-amount-1, #boiler-amount-2, #boiler-amount-3, #boiler-amount-4').on('change', function (event) {
        let idx = parseInt(event.target.id.slice(-1))

        const field = $(this)

        resetNextFields(field)

        const amount = parseInt($('#amount').val())
        const boiler_amounts = getBoilerAmounts()
        const boiler_amount = amount - boiler_amounts

        const boiler_field = '#boiler-' + (idx + 1)
        const chosen_boilers = getChosenBoilers()

        $(boiler_field + ' option').each(function () {
            const option = $(this)

            if ($.inArray(option.val(), chosen_boilers) !== -1) {
                option.prop('disabled', true)
            }
        })

        if (boiler_amount > 0) {
            $(boiler_field).closest('.field').removeClass('hidden')
        }
    })

    $('.tabs .tabs__links a.active').on('click', (e) => {
        e.preventDefault()

        const amount = $('#amount')

        amount.prop('selectedIndex', 0)

        resetNextFields(amount)
    })
}

const populateBoilerAmountField = (field, min, max) => {
    $(field).empty()

    $(field).append($('<option>', {
        value: '',
        text: cascadetool_data.lang.select_amount
    }))

    for (let i = min; i <= max; i++) {
        let text = i === 1 ? cascadetool_data.lang.piece : cascadetool_data.lang.pieces
        text = text.replace('{{amount}}', i)

        $(field).append($('<option>', {
            value: i,
            text
        }))
    }
}

const resetNextFields = current_field => {
    current_field.closest('.field').nextAll().each(function () {
        const field = $(this)
        const select = field.find('select')

        if (select.length === 0) {
            return
        }

        const id = select.attr('id')

        if ($.inArray(id, hideable_fields) === -1) {
            return
        }

        if (select.attr('id').match('^boiler-amount')) {
            select.empty()
        } else {
            select.prop('selectedIndex', 0)
            select.find('option').prop('disabled', false)
        }

        field.addClass('hidden')
    })
}

const getBoilerAmounts = () => {
    let boiler_amounts = 0

    $('#boiler-amount-1, #boiler-amount-2, #boiler-amount-3, #boiler-amount-4').each(function () {
        const boiler_amount = parseInt( $(this).val() )

        if ( Number.isInteger( boiler_amount ) ) {
            boiler_amounts += boiler_amount
        }
    })

    return boiler_amounts
}

const getChosenBoilers = () => {
    const chosen_boilers = []

    $('#boiler-1, #boiler-2, #boiler-3, #boiler-4').each(function () {
        const boiler = $(this).val()

        if (boiler !== '') {
            chosen_boilers.push(boiler)
        }
    })

    return chosen_boilers
}

export {getBoilerAmounts}